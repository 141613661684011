@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
body {
  background: #1f1f1f !important;
  margin: 0;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
nav.navbar {
  
  position: absolute;
  width: 100%;
  z-index: 99;
}
.last-sec-img img:hover {
  border: 3px solid #ff6900;
}
.last-sec-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.last-sec .slick-prev{
  display: none !important;
}
.last-sec .slick-next{
  display: none !important;
}
.slick-list{
  padding: 30px 0px 30px !important;
}
/* responsive start */
@media only screen and (max-width: 768px) {
  .oursolarworkfirst .gradient{
    font-size: 30px !important;
  }
  .oursolarworkWind .investment h4{
    
    font-size: 16px;
  }
  section.aboutVtv .engineeringServicesmain .image-container{
    height: 353px !important;
  }
  /* section.aboutVtv .engineeringServicesmain .image-next img{
    object-position: bottom;
  } */
  .mapSec{
    padding: 15px 0px !important;
  }
  .borderSEC{
    width: 100% !important;
  }
  .card1{
    
    background-size: 100% 100% !important;
  }
  section.WhyVTVEnergy .EnergyRight{
    height: 365px !important  ;
  }
  section.WhyVTVEnergy .EnergyRight .box-rating{
    height: 138px !important;
    width: 170px !important ;
  }
  section.WhyVTVEnergy .EnergyRight .box-rating h2{
    font-size: 62px !important;
  }
  .TabSec .box-innertab {
    padding: 0px !important;
  }
  /* .leaflet-container{
    height: 300px !important;
  } */
  .hero-rightMain{
    border-left: none !important;
    height: 50vh !important;
  }
  .hero-leftMain{
    align-items: end !important;
    height: 60vh !important;
    border-right: none !important;
  }
  .hero-left{
    height: 50vh !important;
    border-right: none !important;
  }
  .hero-right{
    height: 50vh !important;
    border-left: none !important;
  }
  button.navbar-toggler{
    border: none;

  }
  section.vtvEnergy {
    padding: 15px 15px !important;
}
  .navbar{
    
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>) !important;
  }
  .engineeringServices {
    padding: 15px 15px 46px !important;
}
section.counter{
  padding: 15px 15px 46px !important;
  background-color: #1F1F1F;
}
section.map-inquiry{
  padding: 15px 15px 46px !important;
  background-color: #1F1F1F;
}
section.AboutVtv{
  padding: 15px 15px 46px !important;
  background-color: #1F1F1F;
}
section.aboutVtv{
  padding: 15px 15px 46px !important;

}
section.TabSec{
  padding: 15px 15px 46px !important;
  background-color: #1F1F1F;
}
section.WhyVTVEnergy{
  padding: 15px 15px 46px !important;
  background-color: #1F1F1F;
}
.InquiriesSectionSupport {
  background-color: #1F1F1F;
  padding: 24px 7px !important;
}
section.footerSection{
  padding: 60px 6px 0px 6px !important;
}
section.secondSection .secondSectionLeft img{
  object-fit: cover;
  width: 100% !important;
}
.secondSection .secondSectionRight h2 {
  font-size: 30px !important;
}
section.secondSection {
  padding: 15px 15px 46px !important;
}
section.oursolarwork {
  padding: 15px 15px 46px !important;
}
.oursolarwork .oursolarwork-left{
  padding: 20px 20px 20px 20px !important;
    height: 558px !important;
    align-items: flex-start !important;
}
.oursolarwork-right {
  transform: translate(0px, -157px) !important;
}
.CultivateSection {
  padding: 15px 15px 46px !important;
}
.CultivateSection h2 {
  font-size: 36px !important;
}
.oursolarwork .btnwork{
  padding: 11px 26px !important;
  font-size: 18px !important;
}
.InquiriesSection {
  padding: 15px 15px 46px !important;
}
.InquiriesSection h2 {
  font-size: 30px !important;
}
.leaflet-container.leaflet-touch.leaflet-retina.leaflet-safari.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  height: 333px !important;
}
.border-footer{
border-left: 0px solid !important;
}
.navbar-brand img {
  width: 136px;
}
.hero-leftWind .WindText {
  font-size: 30px !important;
}
.oursolarwork .oursolarwork-left h2{
  font-size: 30px !important;

}
section.oursolarworkWind {
  padding: 0px 16px 0px !important;
}
.oursolarworkWind .oursolarwork-leftWind{
  padding: 20px 20px 20px 20px !important;
}
.oursolarworkWind form label{
  margin-bottom: 10px !important;
}
.oursolarworkWind form img{
  object-fit: cover !important;
  width: 100% !important;
}
.oursolarworkWind form{
width: 100% !important;
}
.full-withradius{
  padding: 14px 11px !important;
}
.footerSection .footerMenu ul li{
  list-style: circle;
}
.dert .socialIcon {
  display: flex;
  gap: 20px;
}
.dert .socialIcon .icon {
  padding: 7px !important;
  width: 19px !important;
  height: 19px !important;
}
.footerMenu .cont{
  list-style: none !important;
}
.footerContact .footerMenu li{
  list-style: none !important;
}
.engineeringServices .serviceEn h2{
  font-size: 30px !important;
}
p{
  font-size: 16px !important;
}
.count-box span {
  font-family: "Urbanist", sans-serif;
  font-size: 66px !important;
}
section.vtvEnergy .bgvtv {
  height: 227px !important;
}
section.vtvEnergy .bgvtv h2 {
  font-family: "Urbanist", sans-serif;
  font-size: 38px !important;
}
section.aboutVtv .engineeringServicesmain .serviceEn h2 {
  font-size: 30px !important;
}
section.aboutVtv .engineeringServicesmain div.row {
  flex-direction: column-reverse;
}
section.aboutVtv .engineeringServicesmain .box-innertab h2 {
  font-size: 30px !important;
}
section.aboutVtv .engineeringServicesmain .box-innertab p{
  line-height: 26px !important;
}
.carddff {
  min-width: -webkit-fill-available;

  margin-top: 20px !important;
}
section.featureProjects{
  padding: 15px 15px 46px !important;
}
section.whyVtv{
  padding: 15px 15px 46px !important;

}
section.featureProjects h2 {
  font-size: 30px !important;
}
section.last-sec h2 {
  font-size: 30px !important;
}
.featureProjects ul.mb-3.nav.nav-tabs{
  float: right !important;
}
section.whyVtv .texter h2 {
  font-size: 30px !important;
}
.formbutttontt .form-control::-webkit-input-placeholder {
  font-size: large !important;
}

section.WhyVTVEnergy .EnergyLeft h2 {
  font-size: 30px !important;
}
section.WhyVTVEnergy .EnergyRight img {
  object-fit: cover !important;
  width: 100% !important;
}
.AboutVtv .serviceEn h2 {
  font-size: 30px !important;
}
.AboutVtv .container.engineeringServicesmain .col-lg-12 img{
  object-fit: cover !important;
  width: 100% !important;
}
.missionButton{
  padding: 11px 26px !important;
}
.secondSection .secondSectionRight button{
  padding: 11px 26px !important;
}
.oursolarwork .oursolarworkfirst .btnwork{
  padding: 11px 26px !important;
}
.box-innertab h2{
  font-size: 15px !important;
}
.InquiriesSectionSupport h2 {
  font-size: 30px !important;
}
section.map-inquiry h2 {
  font-size: 28px !important;
}
section.map-inquiry .col-lg-12.col-md-6.col-sm-12>div{
  flex-direction: column !important;
  align-items: center;
}
.hero-leftContact{
  height: 265px !important;
}
.hero-leftWhyVTV{
  height: 265px !important;
}
.hero-leftWind{
  height: 280px !important;
}
section.last-sec .swiper-slide>div{
  width: auto !important;
}
.card .card-body.cole2{
  height: 340px !important;
}
.card .card-body.cole1{
  height: 340px !important;

}
section.whyVtv .service{
  text-align: center !important;
  justify-content: center;
  align-items: center;
}
}
/* responsive end */
.border-footer{
  border-left: 1px solid white;
}
.menuButton{
  padding: 12px 0px !important;
  background: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  border: none !important;
  border-radius: 7px !important;
}
.menunav{
  backdrop-filter: blur(3px);
  background: #FFFFFF24;
  border: none !important;
  border-radius: 2px !important;

}
.oursolarwork .oursolarworkfirst .swiper-button-prev {
  background: hsl(0 0% 100% / 75%);
  height: 60px;
  width: 60px;
  border-radius: 45px;
}
section.WhyVTVEnergy .EnergyRight .box-rating p{
  margin-bottom: 0px;
  font-size: 19px;
  font-weight: 400;
  font-family: "Urbanist", sans-serif;
}
section.WhyVTVEnergy .EnergyRight .box-rating h2{
  padding: 0px;
  margin-bottom: 0px;
  font-size: 85px;
  font-weight: 800;
  font-family: "Urbanist", sans-serif;
}
section.WhyVTVEnergy .EnergyRight .box-rating{
  height: 180px;
  width: 225px;
  padding: 0px 13px;
  border-radius: 6px;
  margin: -6px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background:linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%);
}
section.WhyVTVEnergy .EnergyRight:hover .box-rating{
  background: linear-gradient(125.75deg, #FF3D00 -16.5%, #FFE600 93.09%);

}

section.WhyVTVEnergy .EnergyRight:hover{
  cursor: pointer;
  opacity: 0.8;
}
section.WhyVTVEnergy .EnergyRight{
  background: url(./components/assets/images/vtvMainWhy.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100% !important;
  height: 500px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
.oursolarwork .oursolarworkfirst .swiper-button-next {
  background: hsl(0 0% 100% / 75%);
  height: 60px;
  width: 60px;
  border-radius: 45px;
}
.flickity-viewport{
  height: 26pc !important;
}
.carousel {
  margin: auto;
  max-width: 100% !important;
  height: auto;
  border-radius: 20%;
}
 .carousel-cell {
  margin-right: 35px;
  float: right;
  max-width: 253px;
  width: 100%;
  height: auto;
  /* border-radius: 15px; */
  counter-increment: carousel-cell;
  transition: all .3s ease;
  opacity: 0.3;
  background: transparent;
  filter: blur(6px);
  /* padding: 32px 10px; */
}
 .carousel-cell.is-selected {
  filter: blur(0);
  height: 531px;
  opacity: 1;
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: transparent;
  /* border: 1px solid #ffff; */
}
    
    /* name */
 .carousel-cell .n {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  color:black;
  counter-increment: carousel-cell;
}
    
    /* button */
  .carousel-cell .b {
  cursor: pointer;
  margin: auto;
  width: 180px;
  padding: 1px;
  background: green;
  border: 1px solid green;
  border-radius: 15px;
  font-size: 15px;
  color: white;
  text-align: center;
  counter-increment: carousel-cell;
  transition: all .3s ease;
}

 /* picture */
  .carousel-cell .p {
  border-radius: 15px;
  width: auto;
  height: 140px;
  counter-increment: carousel-cell;
}
 .carousel-cell {
  text-align: center;
}
 .carousel-cell .b:hover {
  background: #00e509;
  transform: scale(1.03);
  font-size: 16px;
}
    
 .carousel-cell .b:active {
  background: white;
  color: green;
  transform: scale(0.9);
  font-size: 16px;
}
    
 .carousel-cell .b-selected {
  cursor: pointer;
  margin: auto;
  width: 180px;
  padding: 1px;
  background: white;
  border: 1px solid green;
  border-radius: 15px;
  font-size: 15px;
  color: green;
  text-align: center;
  counter-increment: carousel-cell;
  transition: all .3s ease;
}
 .carousel-cell .sociallinks ul li {
  list-style: none;
}

 .carousel-cell .sociallinks ul li img {
  width: 24px;
}
 .carousel-cell .sociallinks ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.hero-left:hover  h2{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-left:hover h1{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-left:hover {
  cursor: pointer;
  /* background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/commercial.gif);   */
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  height: 500px;
  /* background-size: 100% 100%; */
  padding-bottom: 22px;
}
.hero-left{
  /* background: url(./components/assets/banner/commercial.gif) no-repeat; */
  /* border: none !important; */
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  /* background-size: 100% 100%; */
  padding-bottom: 22px;
  /* border-right: 2px solid #f75102 ; */
}
.hero-leftMain:hover h1{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.buttonMainHomeBanner{
  padding: 10px 20px;
  background: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  border: none;
  border-radius: 7px;
  padding: 15px 40px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
.hero-leftMain .herofirst h1.text-white {
  line-height: 100px;
  font-size: 68px;
}
.hero-leftMain:hover {
    cursor: pointer;
    background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/heroBanner.png);  
    border: none !important;
    border-radius: 2px !important;
    display: flex;
    background-size: 100% 100%;
}
.engineeringServices{
  padding: 55px 48px;
  background: #1F1F1F;
}
.engineeringServices .serviceEn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.engineeringServices .serviceEn h2{
  padding-bottom: 5px;
  width: fit-content;
  border-bottom: 2px solid white;
  font-family: "Urbanist", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;   
}
.AboutVtv .serviceEn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.AboutVtv .serviceEn h2{
  padding-bottom: 5px;
  width: fit-content;
  border-bottom: 2px solid white;
  font-family: "Urbanist", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;   
}
.hero-leftMain{
  background: url(./components/assets/banner/heroBanner.png) no-repeat;
  /* border: none !important; */
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-size: 100% 100%;
  padding-bottom: 22px;
  /* border-right: 2px solid #f75102; */
}
.hero-leftWind:hover h2{
  font-size: 45px;
  
  line-height: 64px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-leftWind:hover h1{
  font-size: 45px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-leftWind:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/business-partners.png);  
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  height: 500px;
  background-size: 100% 100%;
}
.hero-leftWind .WindText{
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  /* background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */

}
.hero-leftWind{
  background-image: url(./components/assets/banner/business-partners.png);  
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  background-size: 100% 100%;
  padding-bottom: 45px;
}
.hero-leftWhyVTV:hover h2{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-leftWhyVTV:hover h1{
  font-size: 45px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-leftWhyVTV:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/why_vtv.jpg);  
  border: none !important;
  border-radius: 2px !important;
  opacity: 0.8;
  display: flex;
  height: 500px;
  background-size: 100% 100%;
}
.hero-leftWhyVTV .WindText{
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.hero-leftWhyVTV{
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/why_vtv.jpg);  
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  background-size: 100% 100%;
  padding-bottom: 45px;
}
.hero-leftContact:hover h1{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-leftContact:hover h2{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-leftContact:hover {
  cursor: pointer;
  opacity: 0.8;
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/images/heroBanner.jpg);  
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  height: 500px;
  background-size: 100% 100%;
}
.hero-leftContact .WindText{
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.hero-leftContact{
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/images/heroBanner.jpg);  

  /* background: url(./components/assets/images/heroBanner.jpg) no-repeat; */
  border: none !important;
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  background-size: 100% 100%;
  padding-bottom: 45px;
}
.hero-right:hover h1{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-right:hover h2{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-right:hover {
  cursor: pointer;
  /* background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/residential.gif);   */
    border: none !important;
    border-radius: 2px !important;
    display: flex;
    height: 500px;
    /* background-size: 100% 100%; */
    padding-bottom: 22px;
}
.hero-rightMain:hover h1{
  font-size: 45px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.box-innertab p {
  color: white;
}
.TabSec .box-innertab {
  padding: 0px 156px;
}
.hero-leftMain:hover .paraBG {
  color: white;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: white;
}
.hero-rightMain:hover .paraBG {
  color: white;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: white;
}
.hero-rightMain:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%)), url(./components/assets/banner/residential.gif);  
    border: none !important;
    border-radius: 2px !important;
    display: flex;
    background-size: 100% 100%;
}
img{
  -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    cursor: pointer;
}
img:hover {
  -webkit-transform: scale(1.3) !important;
  transform: scale(1.1) !important;
  opacity: 0.7;
}
button.px-4.menuButton:hover {
  animation: fadeIn .2s;
  transition-duration: 0.1s;
}
button.btnSecondSec:hover {
  animation: fadeIn 1s;
  transition-duration: 0.1s;
}
::placeholder {
  color: rgb(192, 192, 192) !important;
  opacity: 1; /* Firefox */
  font-weight: 600;
  font-size: small;
}
.form-control:focus{
  box-shadow: none !important;
}
::-ms-input-placeholder { /* Edge 12 -18 */
  color: white !important;
}
button.btnwork:hover {
  animation: fadeIn 1s;
  transition-duration: 0.1s;
}
.menunav.navbar-nav a.nav-link:hover {
  color: #ffffff;
}
.menunav.navbar-nav a.nav-link.active {
  color: #ff8027;
}
.engineeringServicesmain ul.mb-3.nav.nav-tabs .nav-item .nav-link:hover {
  color: #ff8027;
}
.menunav:hover a.nav-link.active {
  color: white;
}
.menunav:hover {
  transition-duration: 0.8s;
  background: linear-gradient(99.53deg, #ffe600a6 -166.03%, #ff3d00ab 168.83%);
}

button{
  font-family: "Urbanist", sans-serif !important;
}
.oursolarwork .oursolarworkfirst .btnwork{
  background: white;
  font-family: "Urbanist", sans-serif;
  font-size: 22px;
  font-weight: 500;

      border: none;
    border-radius: 3px;
    padding: 15px 30px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}
.hero-right{
  /* background-size: 100% 100% !important; */
background-image: linear-gradient(to right, rgb(0 0 0 / 55%), rgb(0 0 0 / 55%));  
  /* border: none !important; */
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  /* background-size: 100% 100%; */
  padding-bottom: 22px;
  /* border-left: 2px solid #f75102; */
}
.hero-rightMain{
  /* background-size: 100% 100% !important; */
  background: url(./components/assets/banner/residential.gif) no-repeat;
  /* border: none !important; */
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 100vh;
  background-size: 100% 100%;
  padding-bottom: 22px;
  border-left: 2px solid #f75102;
}
.paraBG{
  
  margin-top: 28px;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  /* line-height: 89px; */
  /* letter-spacing: 0em; */
  /* text-align: left; */
  color: #ffffff;
  /* background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.menunav.navbar-nav a.nav-link {
  color: #e2e2e2a3;
  padding: 13px;
  font-size: 18px;
  font-weight: 500;
}
.navbar-toggler:focus{
  box-shadow: none !important;
}
@media only screen and (max-width: 768px) {
  .hero-leftWind:hover h2{
    line-height: 35px;
  }
  .menunav:hover {
    background : black;
  }
  .hero-leftMain .herofirst h1.text-white {
    font-size: 30px !important;
    line-height: normal;
}
.buttonMainHomeBanner{
  padding: 7px 31px;
}

.menunav {
  background: #000000;
}
}
section.hero{
  background-color: #1F1F1F;
}
section.counter{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.map-inquiry{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.AboutVtv{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.TabSec{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.WhyVTVEnergy{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.WhyVTVEnergy .EnergyLeft h2{
  font-size: 45px;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
section.WhyVTVEnergy .EnergyLeft{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

section.featureProjects h2{
  font-family: "Urbanist", sans-serif;
font-size: 64px;
font-weight: 900;
line-height: 86px;
letter-spacing: 0em;
text-align: left;
color: #ffffff;
}
section.featureProjects{
  padding: 55px 48px;
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
}
section.aboutVtv{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
section.whyVtv .texter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
section.whyVtv .texter h2{
  padding-bottom: 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid white;
  font-family: "Urbanist", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
section.whyVtv .texter p{
  text-align: center;
  color: white;
  font-size: 20px;
}
section.whyVtv{
  padding: 55px 48px;
  background-color: #1F1F1F;
}

section.vtvEnergy{
  padding: 55px 48px;
  background-color: #1F1F1F;
}
.secondSection .secondSectionRight{
  color: white;
  text-align: left;
}
section.secondSection{
  background: #1F1F1F;
  padding: 100px 100px;
  color: white;
}
section.oursolarwork{
  background: #1F1F1F;
  padding: 55px 48px;
  color: white;
}
section.oursolarworkWind {
  background: #1F1F1F;
  padding: 0px 55px 0px;
  color: white;
}
.secondSection .secondSectionRight h2{
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;   
}
.oursolarwork .oursolarwork-left{
  font-size: 40px;
  height: 480px;
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 50px 125px 50px 20px;
  border-radius: 7px;
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
  
}
.oursolarwork .oursolarwork-left h2{
  font-family: "Urbanist", sans-serif;
font-size: 45px;
font-weight: 700;
line-height: 49px;
letter-spacing: 0em;
text-align: left;

}   
.oursolarwork .oursolarwork-left p{
  
  font-family: "Urbanist", sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  
}
.oursolarworkWind .oursolarworkfirst{
  margin-bottom: 36px;

}
.oursolarworkWind .oursolarwork-leftWind .formbutttontt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.bordenonr{
  background: none !important;
  border: none !important;
  border-bottom: none;
  border-radius: 0px !important;
  color: white !important;
  font-weight: 900 !important;
  padding: 0px !important;
}
.col-lg-12.col-md-12.col-sm-12.hero-leftWind .herofirst p {
  font-size: 20px !important;
}
.oursolarworkWind .oursolarwork-leftWind {
  /* font-size: 40px; */
  height: 380px;
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 50px 50px 50px 50px;
  /* border-radius: 7px; */
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
}
.oursolarwork-right {
  transform: translate(-130px, 0px);
}
/* .oursolarworkfirst{
  height: 536px;

} */
.oursolarwork .btnwork{
  background: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  font-family: "Urbanist", sans-serif;
  font-size: 22px;
  font-weight: 500;

      border: none;
    border-radius: 3px;
    padding: 15px 30px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}
.oursolarworkWind  .investment:hover{
  background: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  padding: 10px 0px;
  margin-top: 15px;
  border-radius: 9px;
  cursor: pointer;
  transition-delay: .8s;
}
.oursolarworkWind  .investment{
  background: none;
  padding: 10px 0px;
  margin-top: 15px;
  border-radius: 9px;
  border: 3px solid transparent;
  border-image: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  border-image-slice: 1;
  transition: .9s;
}
.amountModal .modal-content{
  background: linear-gradient(98.64deg, #FFE600 -32.66%, #FF3D00 101.88%);
  border-radius: 30px;
}
.amountModal .modal-content label.form-check-label {
  font-size: 14px;
}
.amountModal .modal-content .form-control::placeholder{
  color: #868686 !important;
}
.amountModal .modal-content .modal-footer button{
  background-color: white;
  color: black;
  padding: 10px 60px;
  border: none;
  font-weight: 800;
}
.amountModal .modal-content .modal-footer{
justify-content: center;
}
.amountModal .modal-content .btn-close:focus{
  box-shadow: none;
}
.amountModal .modal-content .btn-close{
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) !important;
  opacity: inherit;
}
.amountModal .modal-content .modal-title{
  font-family: "Urbanist", sans-serif;
  color: black;
  font-weight: 800;
}
.oursolarworkWind .oursolarwork-leftWind h2{
  font-family: "Urbanist", sans-serif;
font-size: 45px;
font-weight: 700;
line-height: 49px;
letter-spacing: 0em;
/* text-align: left; */

}   
.oursolarworkWind .oursolarwork-leftWind p{
  
  font-family: "Urbanist", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  
}
.secondSection .secondSectionRight p{
  font-family: "Urbanist", sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  
}
.secondSection .secondSectionRight button{
  background: linear-gradient(99.53deg, #FFE600 -166.03%, #FF3D00 168.83%);
  border: none;
  border-radius: 3px;
  padding: 15px 30px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
.swiper-slide.swiper-slide-next {

  display: flex;
  height: 499px !important;
  justify-content: flex-start;
  align-items: stretch;
}
.swiper-wrapper{
  align-items: center;
}
.swiper-button-prev:after, .swiper-button-next:after{
  font-weight: 900;
  color: #4c4846 !important;
  font-size: 34px !important;
}
.InquiriesSection{
  background-color: #1F1F1F;
  padding: 55px 48px;
}
.InquiriesSectionSupport{
  background-color: #1F1F1F;
  padding: 55px 48px;
}
.CultivateSection{
  background-color: #1F1F1F;
  background-image: linear-gradient(to right, rgb(0 0 0 / 44%), rgb(0 0 0 / 32%)), url(./components/assets/banner/Cultivate.png);
  background-size: 100% 100%;
  padding: 55px 48px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
}
.CultivateSection h2{
  font-family: "Urbanist", sans-serif;
  font-size: 64px;
  font-weight: 900;
  line-height: 89px;
  letter-spacing: 0em;
  text-align: left;
  
}
.CultivateSection p{
  font-family: "Urbanist", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;  
}
.InquiriesSection h2{
  font-family: "Urbanist", sans-serif;
  font-size: 40px;
  font-weight: 900;
  /* line-height: 89px; */
  /* letter-spacing: 0em; */
  text-align: left;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.InquiriesSectionSupport h2{
  font-family: "Urbanist", sans-serif;
  font-size: 40px;
  font-weight: 900;
  color: white;
  /* line-height: 89px; */
  /* letter-spacing: 0em; */
  text-align: left;
  /* background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent;
}
.InquiriesSection .contactUs{
  border: 4px solid;
  background-color: #000;
  padding: 55px 48px;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
.border-gradient {
  border: 3px solid !important;
  border-radius: 2px;
  border-image-slice: 1 !important;
  border-width: 7px !important;
  /* border-radius: 32px; */
}
.InquiriesSectionSupport .border-gradient {
  border: 3px solid !important;
  border-radius: 2px;
  border-image-slice: 1 !important;
  border-width: 0px !important;
  /* border-radius: 32px; */
}
.border-gradientFooter {
  border: 3px solid !important;
  /* border-radius: 2px; */
  border-image-slice: 41 !important;
  border-bottom-width: 0px !important;
  clip-path: border-box;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 14px;
}
.border {
  font-size: 1.6rem;
  display: grid;
  place-items: center;
  min-height: 200px;
  border: 8px solid;
  padding: 1rem;
}
.full-withradius {
  position: relative;
  background: #1f1f1f;
  background-clip: padding-box;
  border-top-left-radius: 20px;
  padding: 26px 47px;
  border-top-right-radius: 20px;
  /* border: solid 8px transparent; */
}
.footerLogo p {
  font-size: 13px;
  font-weight: 500;
}
.full-withradius:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1px;
  left: 0;
  z-index: -1;
  margin: -4px -4px 0px -4px;
  background: linear-gradient(to right, #ffbd00, #ff4000);
  border-top-left-radius: 20px;
  margin-bottom: 0px !important;
  border-top-right-radius: 20px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to right, #FFE600 -32.66%, #FF3D00 101.88%) !important;
}
.InquiriesSectionSupport .border-gradient-purple {
  border-image-source: linear-gradient(to right, #FFE600 -32.66%, #FF3D00 101.88%) !important;
}
.border-gradient-purpleFooter {
  border-image-source: linear-gradient(to right, #FFE600 -32.66%, #FF3D00 101.88%) !important;
}

.form {
  background: radial-gradient(
    81.52% 81.52% at 67.33% 85.84%,
    #fff7f7 0%,
    #fff3fc 100%
  );
  box-shadow: 0px 59px 44px -27px rgba(0, 0, 0, 0.2),
    inset 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}
.inputBg {
  padding: 20px;
  background: #000;
  box-shadow: 12px 0px 25px -8px rgba(224, 175, 207, 0.5);
}
.InquiriesSectionSupport .inputBg {
  padding: 20px;
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%) !important;
  box-shadow: 12px 0px 25px -8px rgba(224, 175, 207, 0.5);
}
.contactUs.border-gradient.inputBg p{
  font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 43px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}
.contactUsTitle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactUsContact.border-gradient.inputBg p{
  font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 43px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}
.contactUsTitleContact {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.col-lg-6.oursolarworker {
  text-align: left;
}
.formbutttontt .form-control::-webkit-input-placeholder {
  color: white !important;
  font-size: x-large;
}
.form-control::-webkit-input-placeholder {
  color: white !important;
}
.contactUs .form-control {
  background: none !important;
  border: none !important;
  border-bottom: 2px solid #fff !important;
  border-radius: 0px !important;
  color: white !important;
  font-weight: 900 !important;
  padding: 0px !important;
}
.contactUsContact .form-control {
  background: none !important;
  border: none !important;
  border-bottom: 2px solid #fff !important;
  border-radius: 0px !important;
  color: white !important;
  font-weight: 900 !important;
  padding: 0px !important;
}
.form-label {
  margin-top: 20px;
}
section.footerSection {
  background: #1f1f1f;
  padding: 60px 60px 0px 60px;
  text-align: left;
  color: white;
  position: relative;
  z-index: 0;
}
.footerSection .footerMenu ul {
  list-style: none;
  text-decoration: none;
  color: white;
}
.footerSection .footerMenu ul li {
  margin-top: 10px;
}
.footerSection .footerMenu ul li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  margin-top: 11px;
}
#us-map{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
path:hover, circle:hover {
  stroke: #002868 !important;
  stroke-width:2px;
  stroke-linejoin: round;
  fill: #002868 !important;
  cursor: pointer;
}
#path67 {
  fill: none !important;
  stroke: #A9A9A9 !important;
  cursor: default;
}
#info-box {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #ffffff;
  border: 2px solid #BF0A30;
  border-radius: 5px;
  padding: 5px;
  font-family: arial;
}
.mapSec h3{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
text-align: left;
color: #FCFCFC;
}
.mapSec{
  /* background: #1F1F1F; */
  padding: 15px 20px;
}
path.leaflet-interactive {
  outline: none;
}
.buttonMainHomeBanner:hover{
  background: transparent;
  border: 1px solid #ff6900 !important;
  animation: fadeIn .2s;
  transition-duration: 0.1s;
}
button.px-4.menuButton.btn.btn-primary:hover {
  background: transparent;
  border: 1px solid #ff6900 !important;
}
path:hover, circle:hover {
  stroke: #ff4000 !important;
  stroke-width: 2px;
  stroke-linejoin: round;
  fill: #ff6f00 !important;
  cursor: pointer;
}
.leaflet-container {
  border-radius: 20px;
  background: linear-gradient(to right, #FBAB18 -32.66%, #FBAB18 101.88%) !important;
  outline-offset: 1px;
}
.leaflet-pane > svg path.leaflet-interactive {
  filter: drop-shadow(0px 0px 0px black);
  stroke: white;
}
.hero.friendlynewBanner{
  background: linear-gradient(to right, rgba(253, 149, 12, 0.21), rgb(253, 149, 12, 0.21)), url(./components/assets/banner/bannerSolar.jpg);  
  background-size: 100% 100% !important;
  opacity: 0.8;

}
.InquiriesSectionSupport .contactUsContact .leaflet-container {
  border-radius: 20px;
  background: linear-gradient(98.82deg, #ffe60000 -52.77%, #ff3d0000 125.07%) !important;
  outline-offset: 1px;
}
.InquiriesSectionSupport .contactUsContact path.leaflet-interactive {
  stroke: white;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  /* fill: #303030; */
  position: relative;
  z-index: 999;
  opacity: revert-layer;
  background: none;
}
.oursolarworkfirst .gradient{
  font-size: 40px;
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.engineeringServices a {
  color: aliceblue;
  text-decoration: auto;
}
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: none;
}
.leaflet-bottom.leaflet-right {
  display: none;
}
.opoppp{
  background: #1F1F1F !important;
  color: white !important;
}
.dert{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 158px;
}
.dert .socialIcon {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.dert .socialIcon .icon{
  color: white;
    padding: 11px;
    width: 26px;
    height: 26px;
    background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%) !important;
    border-radius: 50%;
    font-size: 27px;
}
.dert .socialIcon .icon path:hover{
  stroke: #1F1F1F !important;
  stroke-width: 2px;
  stroke-linejoin: round;
  fill: #1F1F1F !important;
  cursor: pointer;
}
.dert .socialIcon .icon:hover{
  background: #fff !important;
  color: #1F1F1F;
}
.card{
  padding: 20px;
  background: transparent !important;
  border-color: transparent !important;
}
.card:hover{
  padding: 10px;
  /* background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%) !important; */
  border-color: none !important;
  animation: fadeIn 2s;
  transition-duration: 0.2s;
  cursor: pointer;
  transform: scale(1.1);
}
.card .card-body p{
  color: white;
}
.card .card-body h3{
  color: white;
  font-weight: 600;
}
.card .card-body.cole1 {
  background-image: linear-gradient(to right, rgb(0 0 0 / 23%), rgb(0 0 0 / 28%)), url(./components/assets/images/col1.jpg);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 446px;
  justify-content: flex-end;
}
.card .card-body.cole2 {
  background-image: linear-gradient(to right, rgb(0 0 0 / 23%), rgb(0 0 0 / 28%)), url(./components/assets/images/cole2.jpg);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 446px;
  justify-content: flex-end;
}
.card .card-body.cole3 {
  background-image: linear-gradient(to right, rgb(0 0 0 / 23%), rgb(0 0 0 / 28%)), url(./components/assets/images/cole3.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 381px;
  justify-content: flex-end;
}
.count-box span:hover {
  font-family: "Urbanist", sans-serif;
  font-size: 121px;
  font-weight: 900;
  /* line-height: 25px; */
  letter-spacing: 0em;
  text-align: center;
  /* border: 1.79px solid; */
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: initial;
  -webkit-text-stroke-color: #fafdff;
  animation: fadeIn 1s;
  transition-duration: 0.1s;
  cursor: pointer;
}
.count-box span {
  font-family: "Urbanist", sans-serif;
  font-size: 121px;
  font-weight: 900;
  /* line-height: 25px; */
  letter-spacing: 0em;
  text-align: center;
  /* border: 1.79px solid; */
  background: linear-gradient(135.75deg, #ffffff00 -15.5%, #ff3d0000 106.09%), linear-gradient(12deg, #ffffff00, #ffffff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: thin;
  -webkit-text-stroke-color: #ff6900;
}
.count-box .hrborder {
  border: 1px solid #D2D2D2;
  width: 100%;
  margin: 0px !important;
  margin-bottom: 20px !important;
  opacity: inherit;
  background: #D2D2D2;
  z-index: 99;
  position: relative;
}
.count-box p{
  font-family: "Urbanist", sans-serif;
font-size: 18px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: #ffffff;
}
section.vtvEnergy .bgvtv{
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-size: 100% 100%;
  background-image: linear-gradient(to right, rgb(0 0 0 / 23%), rgb(0 0 0 / 28%)), url(./components/assets/banner/VTV_energy.png);
}
section.vtvEnergy .bgvtv:hover h2 {
  font-weight: 700;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1s;
  transition-duration: 0.1s;
}
section.vtvEnergy .bgvtv h2{
 
  font-family: "Urbanist", sans-serif;
  font-size: 80px;
  font-weight: 900;
  /* line-height: 25px; */
  letter-spacing: 0em;
  text-align: center;
  /* border: 1.79px solid; */
  background: linear-gradient(135.75deg, #ffffff00 -15.5%, #ff3d0000 106.09%), linear-gradient(12deg, #ffffff00, #ffffff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: thin;
  -webkit-text-stroke-color: #fffcf9;
}
section.aboutVtv .engineeringServices .serviceEn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
section.aboutVtv .engineeringServicesmain .serviceEn h2{
  padding-bottom: 5px;
  width: fit-content;
  border-bottom: 2px solid white;
  font-family: "Urbanist", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;   
}

section.aboutVtv .engineeringServicesmain .box-innertab {
  display: flex;
  text-align: left;
  flex-direction: column;
}
section.aboutVtv .engineeringServicesmain .box-innertab h2 {
  font-family: "Urbanist", sans-serif;
font-size: 36px;
font-weight: 900;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: #ffffff;
}
section.aboutVtv .engineeringServicesmain .box-innertab p {
  font-family: "Urbanist", sans-serif;
font-size: 28px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: #ffffff;
}
.engineeringServicesmain ul.mb-3.nav.nav-tabs .nav-item .nav-link {
  transition: none;
  color: #F0F0F038;
}
.engineeringServicesmain ul.mb-3.nav.nav-tabs .nav-item .nav-link:hover {
  color: #F0F0F038;
  border: none;
}
.engineeringServicesmain ul.mb-3.nav.nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background: none;
  border: none;
}
.TabSec ul.mb-3.nav.nav-tabs{
  justify-content: center;
  border: none;
}
.TabSec ul.mb-3.nav.nav-tabs .nav-item .nav-link {
  transition: none;
  color: #F0F0F038;
}
.TabSec ul.mb-3.nav.nav-tabs .nav-item .nav-link:hover {
  color: #F0F0F038;
  border: none;
}
.TabSec ul.mb-3.nav.nav-tabs .nav-item .nav-link.active {
  
  color: #fff;
  background: none;
  border: none;
  border-bottom: 2px solid #FF3D00;
}
.engineeringServicesmain ul.mb-3.nav.nav-tabs {
  border: none;
}
.missionButton{
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
  border: none;
  border-radius: 3px;
  padding: 15px 30px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
section.aboutVtv .engineeringServicesmain .image-container {
  position: relative;
  width: 100%;
  height: 470px; /* Set a fixed height for the container */
  overflow: hidden; /* Hide overflow to keep it within col-lg-6 */
}

section.aboutVtv .engineeringServicesmain .image-prev, section.aboutVtv .engineeringServicesmain .image-active, section.aboutVtv .engineeringServicesmain .image-next {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  transition: transform 0.3s ease-in-out;
}
section.aboutVtv .engineeringServicesmain .image-active {
  box-shadow: -4px 2px 7px 0px #181616;
  transform: translateX(4%);
  z-index: 0;
}

section.aboutVtv .engineeringServicesmain .image-next img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
section.aboutVtv .engineeringServicesmain .image-prev {
  transform: translateX(-2%);
  z-index: 0;
}

section.aboutVtv .engineeringServicesmain .image-next {
  box-shadow: -4px 2px 7px 0px #181616;
  transform: translateX(10%);
  z-index: 2;
}
.card-hover{
  display: none;
}
.carder .card-bodyer:hover .card-hover{
  display: block;
}
.borderSEC {
  height: 369px;
  width: 290px;
  background: linear-gradient(45deg, black, #00000036);
  border-radius: 10px;
  transition: border 1s;
  position: relative;
}
.borderSEC:hover {
  border: 1px solid #fff;
}

section.last-sec .swiper-slide.swiper-slide-next>div{
  height: 368px !important;
}
section.last-sec .swiper-wrapper {
  justify-content: center;
  width: max-content;
}
section.last-sec .swiper-slide>div img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
section.last-sec .swiper-slide>div{
  width: 284px ;
  height: 284px !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%) !important;
  padding: 5px !important;

}
.swiper-pagination-bullet{
  background: white !important;
}
.carddff {
  height: 379px;
  /* width: 300px; */
  cursor: pointer;
  background: #808080;
  border: 3px solid #fff;
  border-radius: 10px;
  transition: background 0.8s;
  overflow: hidden;
  background: #000;
  box-shadow: 0 70px 63px -60px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
nav.navbar.navbar-expand-lg.navbar-light button.px-4.menuButton {
  color: #fff;
}
section.last-sec h2{
  text-align: left;
  font-family: "Urbanist", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(125.75deg, #FFE600 -16.5%, #FF3D00 93.09%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.card0 {
  background: url("./components/assets/images/hassan.jpg") center center no-repeat;
  /* background-size: 300px; */
  background-size: cover !important;
}
.card0:hover {
  background: url("./components/assets/images/hassan.jpg") center no-repeat;
  /* background-size: 600px; */
  animation: fadeIn 2s;
  transition-duration: 0.2s;
}
.card0:hover h2 {
  opacity: 1;
}
.card0:hover .fa {
  opacity: 1;
}
.borderSEC:hover h2{
  color: white;
}
.borderSEC h2{
  color: white;
}
.phone-static{
  padding: 12px;
  background: white;
  color: rgb(255, 101, 0);
  border-radius: 53px;
  margin-left: 2px;
  height: 40px;
  font-size: 31px;
  width: 40px;
}
.arrowup-static{
  padding: 12px;
  background: white;
  color: rgb(255, 101, 0);
  border-radius: 53px;
  margin-left: 2px;
  height: 40px;
  font-size: 31px;
  width: 40px;
  cursor: pointer;
}
.icons svg.svg-inline--fa.fa-linkedin {
  font-size: 35px;
}
.fixed-buttons {
  bottom: 65px;
  right: 52px;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.card1 {
  
  background-size: cover !important;
  background: url("./components/assets/images/bhahameed.png") center center no-repeat;
  /* background-size: 300px; */
}
.card1:hover {
  background-size: cover !important;
  background: url("./components/assets/images/bhahameed.png") center no-repeat;
  animation: fadeIn 2s;
  transition-duration: 0.2s;
}
.card1:hover h2 {
  opacity: 1;
}

.card1:hover .fa {
  opacity: 1;
}
.card2 {
  background: url("https://i.pinimg.com/originals/ee/85/08/ee850842e68cfcf6e3943c048f45c6d1.jpg") center center no-repeat;
  background-size: 300px;
}
.card2:hover {
  background: url("https://i.pinimg.com/originals/ee/85/08/ee850842e68cfcf6e3943c048f45c6d1.jpg") center no-repeat;
  animation: fadeIn 2s;
  transition-duration: 0.2s;
}
.card2:hover h2 {
  opacity: 1;
}
.card2:hover .fa {
  opacity: 1;
}
/* card 3 */

.card3 {
  background: url("./components/assets/images/1112111.png") center  no-repeat;
  background-size: 100%;
}
.card3:hover {
  background: url("./components/assets/images/1112111.png") center no-repeat;
  animation: fadeIn 2s;
  transition-duration: 0.2s;
  background-size: 300px;
}
.card3:hover h2 {
  opacity: 1;
}
.card3:hover .fa {
  opacity: 1;
}
.fa {
  opacity: 0;
  transition: opacity 1s;
}
.icons {
  position: absolute;
  fill: #fff;
  color: #fff;
  height: 130px;
  top: 226px;
  width: 50px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}
.featureProjects ul.mb-3.nav.nav-tabs {
  flex-direction: column;
  border: none;
  display: block;
  width: fit-content;
}
.featureProjects .swiper-wrapper{
  justify-content: center;
}
.featureProjects .swiper-button-prev{
  color: #000;
}
.featureProjects ul.mb-3.nav.nav-tabs .nav-item .nav-link {
  transition: none;
  color: #000000;
}
.featureProjects ul.mb-3.nav.nav-tabs .nav-item .nav-link:hover {
  color: #f0f0f0;
  border: none;
}
.featureProjects ul.mb-3.nav.nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background: #f9ffff59;
  border: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 11px 11px;
  width: 100%;
}
.btn-inquiries{
  background: white !important;
  color: black !important;
  border: none !important;
  border-radius: 0px !important;
}
.btn-inquiries:hover{
  background: black!important;
  color: white !important;
  border: none;
  border-radius: 0px;
}
section.whyVtv .service {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  display: flex;
  cursor: pointer;
  background: transparent;
  transition: transform 0.5s, background 0.5s;
  flex-direction: column;
  text-align: left;
}

section.whyVtv .service i {
  font-size: 40px;
  margin-bottom: 10px;
  color: #303ef7;
}

section.whyVtv .service p {
  font-size: 16px;

}
section.whyVtv .service h2 {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 19px;
}
section.whyVtv .service:hover {
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
  color: #fff;
  transform: scale(1.05);
}

section.whyVtv .service:hover i {
  color: #fff;
}
section.whyVtv .service#consultation:hover img {
  content: url('./components/assets/images/messages.png');
}
section.whyVtv .service#assessment:hover img {
  content: url('./components/assets/images/SiteWhite.png');
}
section.whyVtv .service#design:hover img {
  content: url('./components/assets/images/settingsWhite.png');
}
section.whyVtv .service#proposal:hover img {
  content: url('./components/assets/images/ProposalWhite.png');
}
section.map-inquiry h3{
font-size: 20px;
font-weight: 500;
line-height: 43px;
letter-spacing: 0em;
text-align: left;
color: white;
margin: 0px;
}
section.map-inquiry .map{
  padding: 50px;
}
section.map-inquiry .map img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
section.map-inquiry .gradientCurrentLocation{
  background: linear-gradient(98.82deg, #FFE600 -52.77%, #FF3D00 125.07%);
  height: 20px;
  width: 50px;
  border-radius: 26px;
}
section.map-inquiry h2{
font-size: 36px;
font-weight: 700;
line-height: 43px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}